/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        var adminbar = jQuery('#wpadminbar').outerHeight();
        jQuery('.app-header').css('top',adminbar);
        jQuery('.nav-toggle-wrap').css('top',((adminbar+15) / 16) +'em');

        jQuery(window).scroll(function (event) {
          var scroll = jQuery(window).scrollTop();
          var winw = jQuery(window).outerWidth();

          if(scroll>100){
            jQuery('.app-header').addClass('shrunk');
            jQuery('body').addClass('scrolled');
            if(winw>601){
              jQuery('.app-header').css('top',adminbar);
              jQuery('.nav-toggle-wrap').css('top',((adminbar+15) / 16) +'em');
            }
            if(winw<=600){
              jQuery('.app-header').css('top','0');
              jQuery('.nav-toggle-wrap').css('top',(15 / 16) +'em');
            }
          }
          else{
            jQuery('.app-header').css('height', 'auto')
            jQuery('.app-header').css('top','auto');
            jQuery('.nav-toggle-wrap').css('top',((adminbar+15) / 16) +'em');
            jQuery('.app-header').removeClass('shrunk');
            jQuery('body').removeClass('scrolled');
          }
        });

         $(".slider-list .slides").slick({
          dots: true,
          infinite: true,
          speed: 500,
          arrows: true,
          slidesToShow: 1,
          autoplay: true,
          autoplaySpeed: 4100,
          adaptiveHeight: true,

          responsive:[

          {
            breakpoint: 930,
            settings: {
              arrows: false,
              dots: true,
            }
          },

          ],});
         

        if(navigator.userAgent.indexOf('Mac') > 0){
          $('body').addClass('mac-os');
        }

        $(".navbar-main>li").each(function(){
          sub_child = $(this).find('.sub-menu').children().length;
          if (sub_child>0) {
            $(this).find('> a').append('<span class="expand"><i class="icon icon-angle-down"></i></span>');
          }
        });
        $('.navbar-main li .expand').click(function (e) {
          e.preventDefault();
          $(this).parent('a').parent('li').find('.sub-menu').slideToggle(100);
        });

        $(function() {
          $('.app-core .overlay').click(function() {
            toggleNav();

          });
          $('.nav-toggler').click(function() {
            toggleNav();
          });
        });
        function toggleNav() {
          if ($('#app-wrap').hasClass('show-nav')) {
            $('#app-wrap').removeClass('show-nav');
          } else {
            $('#app-wrap').addClass('show-nav');
          }
        }

        $(".app-menu .menu-nav li").each(function(){
          sub_child = $(this).find('.sub-menu').children().length;
          if (sub_child>0) {
            $(this).find('> a').append('<span class="expand"><i class="icon icon-angle-down"></i></span>');
            $(this).addClass('parent');
          }
        });
        $('.app-menu .menu-nav li .expand').click(function (e) {
          e.preventDefault();
          $(this).parent('a').parent('li').children('.sub-menu').slideToggle(100);
        })


        var winw = jQuery(window).outerHeight();
        winw = winw-adminbar;

        jQuery('.full-height').each(function() {
          $(this).css('min-height',winw);
        });
        jQuery('.half-height').each(function() {
          $(this).css('min-height',winw/2);
        });

        $( window ).resize(function() {
          winw = jQuery(window).outerHeight();
          winw = winw-adminbar;
          jQuery('.full-height').each(function() {
            $(this).css('min-height',winw);
          });
          jQuery('.half-height').each(function() {
            $(this).css('min-height',winw/2);
          });
        });
      // Steps Form
      $('.form-control').each(function() {
        var $this_control =  $(this);
        if ($this_control.val() != ''){
          label = $this_control.parent(".wpcf7-form-control-wrap, .form-control-wrap").prevAll('label');
          label.addClass('active highlight'); 
        }
      });
      $('.wpcf7-validates-as-required').each(function(){
        $(this).prop('required',true);
      });
      $('.form-modal-steps .btn').click(function (e) {
        var targ = $(this).closest('.form-modal-steps').data( "target");
        var targval = $(this).closest('.form-modal-steps').find('.form-control').val();
        $(targ).closest('.field-wrap').find('label').addClass('active');
        $(targ).val(targval);
        e.preventDefault();
      });
      var navListItems = $('div.setup-panel div a'),
      allWells = $('.setup-content'),
      allNextBtn = $('.nextBtn'),
      allPrevBtn = $('.prevBtn');

      allWells.hide();

      navListItems.click(function (e) {
        e.preventDefault();
        var $target = $($(this).attr('href')),
        $item = $(this);

        if (!$item.hasClass('disabled')) {
          navListItems.removeClass('btn-primary').addClass('btn-default');
          $item.addClass('btn-primary');
          allWells.hide();
          $target.show();
          $target.find('input:eq(0)').focus();
        }
      });

      allPrevBtn.click(function(){
        var curStep = $(this).closest(".setup-content"),
        curStepBtn = curStep.attr("id"),
        prevStepWizard = $('div.setup-panel div a[href="#' + curStepBtn + '"]').parent().prev().children("a");

        prevStepWizard.removeAttr('disabled').trigger('click');
      });

      allNextBtn.click(function(){
        var curStep = $(this).closest(".setup-content"),
        curStepBtn = curStep.attr("id"),
        nextStepWizard = $('div.setup-panel div a[href="#' + curStepBtn + '"]').parent().next().children("a"),
        curInputs = curStep.find("input[type='text'],input[type='url'],input[type='email'],input[type='tel']"),
        isValid = true;

        $(".form-group").removeClass("has-error");
        for(var i=0; i<curInputs.length; i++){
          if (!curInputs[i].validity.valid){
            isValid = false;
            $(curInputs[i]).closest(".form-group").addClass("has-error");
          }
        }

        if (isValid)
          nextStepWizard.removeAttr('disabled').trigger('click');
      });

      $('div.setup-panel div a.btn-primary').trigger('click');

      // End step form

    },
    finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        var wow = new WOW(
        {
          boxClass:     'wow',      
          animateClass: 'animated', 
          offset:       10,          
          mobile:       true,       
          live:         true,       
          callback:     function(box) {

          },
          scrollContainer: null 
        }
        );
        wow.init();
      }
    },
    // Home page
    'home': {
     init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {

        $(".slider-main .slides").slick({
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 4000,
        });

       

        $(".slider-brands").slick({
          dots: false,
          infinite: true,
          speed: 500,
          arrows: false,
          slidesToShow: 3,
          slidesToScroll: 3,
          autoplay: true,
          autoplaySpeed: 3000,
          autoplay: true,
          autoplaySpeed: 4000,
          slidesPerRow: 1,
          rows: 2,
          responsive:[
          {
            breakpoint: 450,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 580,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            }
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            }
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            }
          },
          ],
        });

        $('.slider-main .slides').on('init', function(e, slick) {
          var $firstAnimatingElements = $('div.slide-item:first-child').find('[data-animation]');
          doAnimations($firstAnimatingElements);    
        });
        $('.slider-main .slides').on('beforeChange', function(e, slick, currentSlide, nextSlide) {
          var $animatingElements = $('div.slide-item[data-slick-index="' + nextSlide + '"]').find('[data-animation]');
          doAnimations($animatingElements);    
        });

        function doAnimations(elements) {
          var animationEndEvents = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
          elements.each(function() {
            var $this = $(this);
            var $animationDelay = $this.data('delay');
            var $animationType = 'animated ' + $this.data('animation');
            $this.css({
              'animation-delay': $animationDelay,
              '-webkit-animation-delay': $animationDelay
            });
            $this.addClass($animationType).one(animationEndEvents, function() {
              $this.removeClass($animationType);
            });
          });
        }


        $(document).on("scroll", onScroll);
        $('a[href^="#"]').on('click', function (e) {
          e.preventDefault();
          $(document).off("scroll");

          $('a').each(function () {
            $(this).removeClass('active');
          })
          $(this).addClass('active');

          var target = this.hash,
          menu = target;
          $target = $(target);
          $('html, body').stop().animate({
            'scrollTop': $target.offset().top+2
          }, 500, 'easeInOutExpo', function () {
            window.location.hash = target;
            $(document).on("scroll", onScroll);
          });
        });



        function onScroll(event){
          var scrollPos = $(document).scrollTop();
          var adminbar2 = jQuery('#wpadminbar').outerHeight();
          $('#menu-scroll a').each(function () {
            var currLink = $(this);
            var refElement = $(currLink.attr("href"));
            if (refElement.position().top <= scrollPos+adminbar2+100 && refElement.position().top + refElement.height() > scrollPos+adminbar2+100) {
              $('#menu-scroll ul li a').removeClass("active");
              currLink.addClass("active");
            }
            else{
              currLink.removeClass("active");
            }
          });
        }

      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'gallery': {
      init: function() {
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

    //carousel


    var setText = $('.breadcrumb .set-text');



})(jQuery); // Fully reference jQuery after this point.
